import axiosIns from '@/libs/axios'

export default class GeneralApis {
getDataInfo = async () => await axiosIns.get('document/general')

    SaveGeneralInfo = async (data, streamFunction) => {
      const formData = new FormData()
      await Object.keys(data).forEach(element => {
        if (data[element]) {
          formData.append(element, data[element])
        }
      })
      return await axiosIns.post('document/general/action', formData, {
        onUploadProgress: e => {
          if (streamFunction) {
            streamFunction(e)
          }
        },
      })
    }

    getIdTypeList = async () => await axiosIns.get('document/identity-type')
}
