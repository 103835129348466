const fileSize = {
  data() {
  },
  methods: {
    checkImageSize(file, name) {
      if (file.size > (this.maxUploadSize * 1024)) {
        this.toast(`${name} is too big >${this.maxUploadSize}`, 'danger')
        this.pending = false
        return false
      }
      return true
    },
  },
}
export default fileSize
export const _ = null
